/*------reset---------------------*/

* {
    box-sizing: border-box;
}

input:focus, button:focus, a:focus, textarea:focus, select:focus {
    box-shadow: none !important;
}

input[type=number]::-webkit-inner-spin-button, input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
}

input {
    font-family: 'Inter', sans-serif;
}

a, a:hover {
    text-decoration: none;
}

p, label, ul, li, form, dl, dd {
    margin: 0;
    padding: 0;
    list-style: none;
}

textarea {
	resize: none;
}

h1, h2, h3, h4, h5, h6 {
    margin: {
        top: 0;
        bottom: 0;
    }
    font-family: 'Inter', sans-serif;
}

/*------utility---------------------*/

.inter {
    font-family: 'Inter', sans-serif;
}

.poppins {
    font-family: 'Poppins', sans-serif;
}

.switzer {
    font-family: 'Switzer', sans-serif;
}

.flex {
    display: flex;
    align-items: center;

    &.space {
        justify-content: space-between;
    }
}

a.no-style {
	color: #212529;
}

.text-whitesmoke {
    color: whitesmoke;
}

.text-black {
    color: #000;
}

.bg-whitesmoke {
    background: whitesmoke;
}

.pointer {
    cursor: pointer;
    &.bg-hover:hover {
        background-color: rgba(0, 0, 0, 0.07);
    }
}

.move {
    cursor: move;
}

.grab {
    cursor: grab;
}

.grabbing {
    cursor: grabbing;
}

.no-margin {
	margin: 0 !important;
}

.no-padding {
	padding: 0 !important;
}

.uc {
    text-transform: uppercase;
}

.strike {
    text-decoration: line-through
}

.p-16 {
    padding: 16px;
}

.pt-16 {
    padding-top: 16px;
}

.pb-16 {
    padding-bottom: 16px;
}

.gap-12 {
    gap: 12px;
}

.gap-16 {
    gap: 16px;
}

.gap-32 {
    gap: 32px;
}

.row-gap-5 {
    row-gap: 5px;
}

.row-gap-10 {
    row-gap: 10px;
}

.row-gap-15 {
    row-gap: 15px;
}

.row-gap-20 {
    row-gap: 20px;
}

.row-gap-25 {
    row-gap: 25px;
}

.row-gap-30 {
    row-gap: 30px;
}

.align-center {
    align-items: center !important;
}

.flex {
    display: flex;
    width: 100%;

    &.end {
        justify-content: flex-end;
    }
}

.text-center {
    text-align: center !important;
}
	
form button:not(:disabled) {
    cursor: pointer;
}
